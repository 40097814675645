import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Box, IconButton, InputAdornment, Link, Typography } from "@mui/material";
import { Layout as AuthLayout } from "../components/layouts/auth-layout";
import { paths } from "../data/paths";
import { GuestGuard } from "../guards/guest-guard";
import { useAuth } from "../hooks/use-auth";
import { useMounted } from "../hooks/use-mounted";
import { usePageView } from "../hooks/use-page-view";
import { useRouter } from "../hooks/use-router";
import { toast } from "react-hot-toast";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { requestStatus } from "../data/status";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const initialValues = {
  email: "",
  password: "",
  otp: "",
  otpProcessed: "no",
  submit: null,
};

const validationSchema = Yup.lazy((values) => {
  const flag = values.otpProcessed === "yes";
  return Yup.object({
    email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
    password: Yup.string().max(255).required("Password is required"),
    otp: flag ? Yup.number().required("One-time password is required") : Yup.number().notRequired(),
  });
});

const Page = () => {
  const isMounted = useMounted();
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const { signIn, signInWithOtp } = useAuth();
  const [otpSent, setOtpSent] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        if (otpSent) {
          const resp = await signInWithOtp(values.email, values.otp);
          setOtpVerified(true);
          setShowPassword(false)
          if (isMounted()) {
            if (resp.status == "success") {
              router.push(returnTo || paths.clients.path);
              toast("Login Successfully", {
                position: "top-right",
                style: { backgroundColor: "#14A44D" },
              });
            }
          }
          setOtpSent(false)
        } else {
          const response = await signIn(values.email, values.password);
          if (response.status == requestStatus.SUCCESS) {
            setOtpSent(true);
            setShowPassword(false)
            values.otpProcessed = "yes";
          }
          setSeverity(response.status);
          setMsg("One-time password sent to email successfully.");
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.response.data.message });
          helpers.setSubmitting(false);
        }
      }
    },
  });
  const goToOtpLogin = () => {
    router.push(paths.auth.otp.path);
  };

  usePageView();

  return (
    <div>
      <Card elevation={16}>
        <CardHeader
          subheader={
            <Typography color="text.secondary" variant="body2">
              Do you want to log in with one-time-password? &nbsp;
              <Link
                onClick={goToOtpLogin}
                underline="hover"
                variant="subtitle2"
              >
                Click here
              </Link>
            </Typography>
          }
          sx={{ pb: 0 }}
          title="Log in"
        />
        <CardContent>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
                InputProps={{ readOnly: otpSent }}
              />
              {!otpSent && <TextField
                className="showSvgImage"
                error={!!(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword ? 'hide the password' : 'display the password'
                        }
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />}
              <input name="otpProcessed" type="hidden" />
              {otpSent && (
                <TextField
                  className="showSvgImage"
                  error={!!(formik.touched.otp && formik.errors.otp)}
                  fullWidth
                  helperText={formik.touched.otp && formik.errors.otp}
                  label="One-time password"
                  name="otp"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.otp}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword ? 'hide the password' : 'display the password'
                          }
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </Stack>
            {formik.errors.submit && (
              <FormHelperText error sx={{ mt: 3 }}>
                {formik.errors.submit}
              </FormHelperText>
            )}
            <Button
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
            >
              {!otpSent ? "Log In" : "Verify one-time password"}
            </Button>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <Link
                onClick={() => router.push("/forgotpassword")}
                underline="hover"
                variant="subtitle2"
              >
                Forgot password?
              </Link>
            </Box>
          </form>
        </CardContent>
      </Card>
      {otpSent && !otpVerified && (
        <Stack spacing={3} sx={{ mt: 3 }}>
          <Alert severity={severity}>
            <div>{msg}</div>
          </Alert>
        </Stack>
      )}
    </div>
  );
};

Page.getLayout = (page) => (
  <GuestGuard>
    <AuthLayout>{page}</AuthLayout>
  </GuestGuard>
);

export default Page;
